body {
  word-wrap: break-word;
  background: #fdfdfd;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}
ul,
ol {
  list-style: none;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.header0 {
  background: rgba(0, 21, 41, 0.95);
  width: 100%;
  z-index: 1;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
  position: relative;
  top: 0;
}
.header0 .home-page {
  padding: 0 24px;
}
.header0-logo {
  display: inline-block;
  position: relative;
  width: 150px;
  line-height: 64px;
}
.header0-logo img {
  vertical-align: middle;
  display: inline-block;
}
.header0-logo a {
  display: block;
}
.header0-menu {
  float: right;
}
.header0-menu > .ant-menu {
  line-height: 62px;
  background: transparent;
  color: #fff;
  height: 64px;
  border-bottom-color: transparent;
  position: relative;
}
.header0-menu > .ant-menu a {
  color: #fff;
}
.header0-menu > .ant-menu a:hover {
  color: #1890ff;
}
.header0 .ant-menu-item-selected a {
  color: #1890ff;
}
@media screen and (max-width: 767px) {
  .header0-logo {
    z-index: 101;
  }
  .header0.home-page-wrapper .home-page {
    padding: 0 24px;
  }
  .header0 .header0-menu {
    height: auto;
    float: inherit;
    position: relative;
    left: -24px;
    width: calc(100% + 48px);
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header0 .header0-menu li {
    padding: 0 24px;
  }
  .header0 .header0-menu li.ant-menu-submenu {
    padding: 0;
  }
  .header0 .header0-menu .ant-menu-submenu .ant-menu-sub {
    padding: 0 24px;
  }
  .header0-mobile-menu {
    width: 16px;
    height: 14px;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 100;
  }
  .header0-mobile-menu em {
    display: block;
    width: 100%;
    height: 2px;
    background: #fff;
    margin-top: 4px;
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header0-mobile-menu :first-child {
    margin-top: 0;
  }
  .header0 .ant-menu {
    height: auto;
    overflow: hidden;
    background: #001529;
  }
  .header0 .ant-menu .ant-menu-item-selected {
    border: none;
  }
  .header0 .ant-menu a {
    color: #fff;
  }
  .header0 .ant-menu a:hover {
    color: #fff;
  }
  .header0 .ant-menu .ant-menu-item-selected a {
    color: #fff;
  }
  .header0 .open {
    height: auto;
  }
  .header0 .open .header0-mobile-menu em:nth-child(1) {
    transform: translateY(6px) rotate(45deg);
  }
  .header0 .open .header0-mobile-menu em:nth-child(2) {
    opacity: 0;
  }
  .header0 .open .header0-mobile-menu em:nth-child(3) {
    transform: translateY(-6px) rotate(-45deg);
  }
  .header0 .open > .header0-menu {
    opacity: 1;
    pointer-events: auto;
  }
}
.banner0 {
  width: 100%;
  height: 100vh;
  position: relative;
  text-align: center;
  border-color: #666;
  background-image: url("https://zos.alipayobjects.com/rmsportal/gGlUMYGEIvjDOOw.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}
.banner0 .banner0-text-wrapper {
  display: inline-block;
  position: absolute;
  top: 20%;
  margin: auto;
  left: 0;
  right: 0;
  font-size: 14px;
  color: #fff;
  width: 550px;
}
.banner0 .banner0-text-wrapper > .queue-anim-leaving {
  position: relative !important;
}
.banner0 .banner0-title {
  width: 350px;
  left: 30px;
  min-height: 60px;
  margin: auto;
  display: inline-block;
  font-size: 40px;
  position: relative;
}
.banner0 .banner0-content {
  margin-bottom: 20px;
  word-wrap: break-word;
  min-height: 24px;
}
.banner0 .banner0-button {
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
  box-shadow: 0 0 0 transparent;
  line-height: 40px;
  font-size: 16px;
  height: 40px;
  transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.banner0 .banner0-button:hover {
  color: #fff;
  border-color: #fff;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 10px rgba(50, 250, 255, 0.75);
}
.banner0 .banner0-button:focus {
  color: #fff;
  border-color: #fff;
}
.banner0 .banner0-button.queue-anim-leaving {
  width: auto;
}
.banner0 .banner0-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  left: 50%;
  margin-left: -12px;
  color: #fff;
}
@media screen and (max-width: 767px) {
  .banner0 {
    background-attachment: inherit;
  }
  .banner0 .banner0-text-wrapper {
    width: 90%;
  }
  .banner0 .banner0-title {
    width: 90%;
    left: 0;
  }
}
.banner {
  height: 200px;
  background-image: linear-gradient(to right, rgba(236, 236, 236, 0.6), rgba(0, 21, 41, 0.6)), url("../img/hero.jpeg");
  background-size: cover;
  background-position: center;
  position: relative;
}
.banner__text-box {
  display: block;
  position: absolute;
  top: 60%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.ant-divider-horizontal {
  margin: 10px 0 24px 0px;
}
.footer1-wrapper {
  background: #001529;
  overflow: hidden;
  position: relative;
  min-height: 400px;
  color: #999;
}
.footer1-wrapper .footer1 .home-page {
  padding: 64px 24px 80px;
}
.footer1-wrapper .footer1 .block {
  padding: 0 32px;
}
.footer1-wrapper .footer1 .block .logo {
  max-width: 180px;
}
.footer1-wrapper .footer1 .block .slogan {
  font-size: 12px;
  margin-top: -20px;
}
.footer1-wrapper .footer1 .block > h2 {
  margin-bottom: 24px;
  color: #ccc;
}
.footer1-wrapper .footer1 .block > div {
  line-height: 24px;
}
.footer1-wrapper .footer1 .block > div a {
  color: #999;
}
.footer1-wrapper .footer1 .block > div a:hover {
  color: #1890ff;
}
.footer1-wrapper .copyright-wrapper {
  width: 100%;
  border-top: 1px solid rgba(233, 233, 233, 0.1);
}
.footer1-wrapper .copyright-wrapper .home-page {
  padding: 0 24px;
  overflow: hidden;
}
.footer1-wrapper .copyright-wrapper .copyright {
  height: 100px;
  text-align: right;
  line-height: 80px;
  float: right;
}
@media screen and (max-width: 767px) {
  .footer1 {
    height: 550px;
  }
  .footer1 > ul {
    width: 90%;
    margin: 20px auto 0;
    padding: 10px 0;
  }
  .footer1 > ul > li {
    width: 100%;
  }
  .footer1 > ul > li h2 {
    margin-bottom: 10px;
  }
  .footer1 > ul > li li {
    display: inline-block;
    margin-right: 10px;
  }
  .footer1 .copyright span {
    width: 90%;
  }
}
.card {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 3px;
  box-shadow: 0 1.5px 4px rgba(0, 0, 0, 0.15);
}
.card__bg {
  display: inline-block;
  object-fit: cover;
  width: 100%;
  height: 175px;
}
.card__bg-logo {
  top: -60px;
  object-fit: cover;
  width: 60px;
  height: 60px;
  border: 0.5px solid #e9e9e9;
}
.card__logo {
  height: 65px;
  width: 65px;
}
.ant-card-meta-description {
  line-height: 3ex;
  height: 15ex;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}
.u-center-text {
  text-align: center;
}
.u-margin-bottom-small {
  margin-bottom: 3rem;
}
.u-margin-bottom-medium {
  margin-bottom: 5rem;
}
.u-margin-bottom-large {
  margin-bottom: 10rem;
}
.u-margin-top-small {
  margin-top: 2rem;
}
.u-margin-top-medium {
  margin-top: 4rem;
}
.heading-primary {
  color: #fff;
  backface-visibility: hidden;
  margin-bottom: 2rem;
}
.heading-primary--main {
  display: block;
  font-size: 2.5rem;
  font-weight: 500;
}
.heading-secondary {
  font-size: 2rem;
  font-weight: 500;
  display: inline-block;
}
.ant-card-actions {
  background: #1890ff;
  padding: 0px;
  border-radius: 0px 0px 1px 1px;
}
.ant-card-actions > li {
  margin: 0px;
}
.ant-card-actions > li > span {
  padding: 10px;
  width: 100%;
}
.ant-card-forms {
  margin: 20px;
  color: #1890ff;
}
.ant-card-body {
  padding: 15px;
}
.lead {
  font-size: 16px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.45);
}
.section-heading h2 {
  line-height: 40px;
  font-size: 40px;
  font-weight: 300;
}
.heading-secondary {
  font-weight: 400;
}
.result-primary {
  color: #1890ff;
}
.heading-primary--main {
  font-weight: 500;
  font-size: 44px;
}
.info {
  text-align: justify;
}
.selector {
  position: absolute;
  display: inline;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
}
.exception {
  align-items: center;
  height: 80%;
  min-height: 500px;
}
.exception .imgBlock {
  width: 100%;
  zoom: 1;
}
.exception .imgBlock:before,
.exception .imgBlock:after {
  content: " ";
  display: table;
}
.exception .imgBlock:after {
  clear: both;
  visibility: hidden;
  font-size: 0;
  height: 0;
}
.exception .imgEle {
  height: 360px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: auto;
}
.exception .content h1 {
  color: #434e59;
  font-size: 72px;
  font-weight: 600;
  line-height: 72px;
  margin-bottom: 24px;
}
.exception .content .desc {
  color: rgba(0, 0, 0, 0.45);
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 16px;
}
.exception .content .actions button:not(:last-child) {
  margin-right: 8px;
}
